html {
  //height: 100%;
}

body {
  //height: 100%;
  //font-size: 15px;
  font-weight: lighter;
  padding: 0;
  margin: 0;
}

img{
  border:0;
  padding:0;
  margin:0;
  vertical-align: middle;
  max-width: 100%;
  width: auto\9; /* ie8 */
}

a {
  text-decoration: none!important;
  color: #000;
}

ul {
  list-style-type:none;
}

hr {
  margin-top: 0;
  margin-bottom: 10px;
  border-top: 1px solid #e4b9b9;
}

i {
  margin-right: 3px;
}

input[type=checkbox]{
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 5px;
  vertical-align: middle;
}

input[type=file] {
  display: none;
}

.btn {
  font-weight: 300 !important;
}

.btn-deep-blue {
  color: #fff;
  background-color: #193162;
  border-color: #223458;
  &:focus,&:visited {
    color: #fff;
    background-color: #193162;
    border-color: #223458;
  }
  &:hover {
    color: #fff;
    background-color: #232E43;
    border-color: #232E43;
  }
}

span.hover-theme {
  cursor: pointer;
  margin: 0 3px;
  &:hover {
    color: red;
  }
}